import axios from '@/api/axios'

// 服务器代理地址
const BASE_URL = process.env.VUE_APP_BASE_URL

// ================================= 《 公共 》


// ================================= 《 推广数据 》

// 链接数据列表
export function advList(parameter) {
  return axios({
    url: BASE_URL + '/agent/adv_list',
    method: 'get',
    params: parameter
  })
}

// 链接数据详情
export function advDetail(parameter) {
  return axios({
    url: BASE_URL + '/agent/adv_detail',
    method: 'get',
    params: parameter
  })
}

// 创建推广链接
export function popularizeCreate(parameter) {
  return axios({
    url: BASE_URL + `/agent/create`,
    method: 'post',
    data: parameter
  })
}

// 数据汇总列表
export function advDataList(parameter) {
  return axios({
    url: BASE_URL + '/agent/adv_data_list',
    method: 'get',
    params: parameter
  })
}

// 手动刷新
export function advRefreshData(parameter) {
  return axios({
    url: BASE_URL + '/agent/refresh_adv_data',
    method: 'get',
    params: parameter
  })
}

// 获取刷新时间
export function advRefreshTime(parameter) {
  return axios({
    url: BASE_URL + '/agent/get_refresh_time',
    method: 'get',
    params: parameter
  })
}

// 用户权限菜单
// export function menusPermissions (parameter) {
//   return axios({
//     url: BASE_URL + '/user/menus',
//     method: 'post',
//     data: parameter
//   })
// }

// ================================= 《 内容管理 》

// 片库列表
export function dramaList(parameter) {
  return axios({
    url: BASE_URL + '/agent/agent_drama_list',
    method: 'get',
    params: parameter
  })
}

// 片库 - 剧集详情
export function agentDramaDetail(parameter) {
  return axios({
    url: BASE_URL + '/agent/agent_drama_detail',
    method: 'get',
    params: parameter
  })
}

// ===================================《 结算中心 》

// 每日分成数据
export function cashDayList(parameter) {
  return axios({
    url: BASE_URL + `/agent/cash_day_list`,
    method: 'get',
    params: parameter
  })
}

// 项目结算总信息
export function cashProjectCash(parameter) {
  return axios({
    url: BASE_URL + `/agent/project_cash`,
    method: 'get',
    params: parameter
  })
}

// 开始结算
export function cashBeginCash(parameter) {
  return axios({
    url: BASE_URL + `/agent/begin_cash`,
    method: 'get',
    params: parameter
  })
}

// 结算
export function cashCashSave(parameter) {
  return axios({
    url: BASE_URL + `/agent/cash_save`,
    method: 'post',
    data: parameter
  })
}

// 结算列表
export function cashList(parameter) {
  return axios({
    url: BASE_URL + `/agent/cash_list`,
    method: 'get',
    params: parameter
  })
}